/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, Title, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Text, ContactForm, Subtitle, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu pb--08 pl--40 pr--40 pt--08" menu={true} name={"menu"}>
          
          <Menu className="pb--08 pt--08" fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box" style={{"maxWidth":80}}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/o/13892/6ebff6239bed44848bc5636adf43b55e.jpg"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":83}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="menu-logo-box" style={{"maxWidth":452}}>
              
              <Title className="title-box ff--1 fs--48 w--500 ls--004 lh--14" style={{"maxWidth":324}} content={"Hezké stavby"}>
              </Title>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--400 pr--10" href={"#sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--400 pr--10" href={"#reference"} target={""} content={"Reference"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--400 pr--10" href={"/fotky"} target={""} content={"Galerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--400 pr--10" href={"#sluzby"} target={""} content={"Informace"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5 fs--18 w--400" href={"#kontakt"} target={""} content={"Kontaktovat"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-apxycu --full" name={"uvod"} style={{"backgroundColor":"rgba(0,0,0,1)"}} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1 pb--40 flex--bottom" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62 w--400 title-box--invert lh--1" style={{"maxWidth":640}} content={"Stavební a zednické práce"}>
              </Title>

              <Text className="text-box fs--20 text-box--invert ls--02 lh--16 mt--20" style={{"maxWidth":640}} content={"Provádíme a zajistíme kompletní stavební a zednické práce, včetně rekonstrukcí"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5" href={"#paticka"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--20 pt--20 flex--center" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/88e67c255f7d45fbadefead760a44b9f.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Stavby i rekonstrukce"}>
              </Title>

              <Text className="text-box" content={"jsme otevření pro nové výstavby, rekonstrukce i opravy&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/kvetinarstvi/1c76659180bd4159b6d9b4804db35767.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Exteriér i interiér"}>
              </Title>

              <Text className="text-box" content={"koupelna, plot nebo terasa? <br>nevadí :)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/1bf57d65067e4fcd82d8e6c77685617b.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box w--300 ls--002" content={"Kompletní servis<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"rekonstrukce podkroví, malování, úklid po stavbě?"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13892/ae59e5acd3b946e5902ba216f426cfe5_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13892/ae59e5acd3b946e5902ba216f426cfe5_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/ae59e5acd3b946e5902ba216f426cfe5_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/ae59e5acd3b946e5902ba216f426cfe5_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/ae59e5acd3b946e5902ba216f426cfe5_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/ae59e5acd3b946e5902ba216f426cfe5_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13892/e0290fd01a744cd2ac6dfb479ef76012_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13892/e0290fd01a744cd2ac6dfb479ef76012_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/e0290fd01a744cd2ac6dfb479ef76012_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/e0290fd01a744cd2ac6dfb479ef76012_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/e0290fd01a744cd2ac6dfb479ef76012_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/e0290fd01a744cd2ac6dfb479ef76012_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"reference"} style={{"paddingTop":null,"backgroundColor":"var(--color-variable-1--darken)"}}>
          
          <ColumnWrap className="column__flex --center el--1 pb--60 pt--60" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 w--400 title-box--invert" content={"<span style=\"text-decoration-line: underline;\">Reference</span>"}>
              </Title>

              <Text className="text-box fs--20 text-box--invert lh--16" style={{"maxWidth":510}} content={"Na galerii se pracuje, několik fotografií můžete vidět na&nbsp; další straně v sekci fotogalerie."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5 fs--20" href={"/fotky"} content={"Fotogalerie"}>
              </Button>

              <Image className="--center" src={"https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=2000x_.JPG 2000w"} lightbox={true} position={null}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=860x_.JPG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"reference-2"} style={{"backgroundColor":"rgba(232, 239, 234, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3 mt--0 flex--top" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":396,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image className="--shape2" src={"https://cdn.swbpg.com/t/13892/dc8a3c8efc5d493386dc91c81f2bea60_s=860x_.JPG"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":184}} srcSet={"https://cdn.swbpg.com/t/13892/dc8a3c8efc5d493386dc91c81f2bea60_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/dc8a3c8efc5d493386dc91c81f2bea60_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/dc8a3c8efc5d493386dc91c81f2bea60_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/dc8a3c8efc5d493386dc91c81f2bea60_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/dc8a3c8efc5d493386dc91c81f2bea60_s=2000x_.JPG 2000w"} position={null}>
              </Image>

              <Title className="title-box mt--06" content={"Rekonstrukce"}>
              </Title>

              <Text className="text-box" content={"menší opravy i rekonstrukce, včetně bytových jader"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=860x_.JPG"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=2000x_.JPG 2000w"}>
              </Image>

              <Title className="title-box mt--06" content={"Sádrokartony"}>
              </Title>

              <Text className="text-box" content={"podhledy i příčky, podkroví"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=860x_.JPG"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=2000x_.JPG 2000w"}>
              </Image>

              <Title className="title-box mt--06" content={"Nová výstavba"}>
              </Title>

              <Text className="text-box" content={"cokoliv od plotu až po komín"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt"} style={{"backgroundColor":"var(--color-variable-1--darken)"}}>
          
          <ColumnWrap className="column__flex el--2 pb--60 pt--60" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 title-box--invert" content={"<span style=\"text-decoration-line: underline;\">Stavební a zednické práce</span>"}>
              </Title>

              <Text className="text-box fs--20 text-box--invert ls--0 lh--16" content={"Rádi Vám navrhneme řešení a připravíme nabídku ."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5" href={"#paticka"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--20 text-box--invert lh--16" content={"Pokud chcete řešit výstavbu, opravu, rekonstrukci, neváhejte se na nás obrátit."}>
              </Text>

              <Text className="text-box fs--20 w--300 text-box--invert ls--0 lh--16" content={"Provádíme stavební a zednické práce, stavbu ,obklady, příčky a podhledy, terasy, schodiště, omítky a fasády, ploty, ...&nbsp;&nbsp;"}>
              </Text>

              <Text className="text-box fs--20 text-box--invert lh--16" content={"Využijte kontaktní formulář nebo přímo uvedený kontakt níže"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-cg66vr bg--bottom pb--10 pt--80" name={"formular"} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--25 pt--25" columns={"1"}>
            
            <ColumnWrapper className="mb--0">
              
              <Title className="title-box fs--48 title-box--invert mb--0" content={"Ozveme se Vám"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mt--0">
              
              <ContactForm className="--shape2 --shape2 --style3 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Kontaktní e-mail","type":"email","placeholder":"Zadejte Váš e-mail"},{"name":"Kontaktovat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--justify" name={"paticka"} style={{"marginBottom":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex el--3 mb--60 mt--60" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Hezké stavby s.r.o."} maxWidth={"240"}>
              </Title>

              <Text className="text-box text-box--left fs--16" style={{"maxWidth":202}} content={"Nezvěstice 38, NEZVĚSTICE 332 04<br>IČ: 17055563<br>DIČ: CZ17055563<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--0">
              
              <Title className="title-box" content={"Kontakt"} maxWidth={"180"}>
              </Title>

              <Subtitle className="subtitle-box fs--18 lh--12" style={{"maxWidth":209}} content={"<span style=\"color: rgb(119, 149, 162);\"><br>ozvi.se@hezke-stavby.cz</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18 mt--10" content={"<span style=\"color: rgb(119, 149, 162);\">739 605 898</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left pl--0">
              
              <Button className="btn-box" href={"#menu"} content={"nahoru"}>
              </Button>

              <Image src={"https://cdn.swbpg.com/o/13892/6ebff6239bed44848bc5636adf43b55e.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2 mb--0" name={"mapa"}>
          
          <FullmapWrap className="pb--02 pt--0">
            
            <Subtitle className="subtitle-box subtitle-box--center" content={"Nezvěstice"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full mb--0" style={{"maxWidth":""}}>
            
            <FullmapCover zoom={"10"} place={"Nezvěstice"} style={{"paddingTop":0,"paddingLeft":0,"marginBottom":0}}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}